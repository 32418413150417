"use client";
import Image from "next/image";
import Link from "next/link";
import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { toast } from "react-toastify";
import { motion } from "framer-motion";
import "react-toastify/dist/ReactToastify.css";
import { useForm } from "../../context/FormContext";

export default function Footer() {
  const [email, setEmail] = useState("");
  const [isToastActive, setIsToastActive] = useState(false);

  const { openForm } = useForm();

  const mutation = useMutation({
    mutationFn: async (email) => {
      try {
        const response = await axios.post(
          `${process.env.NEXT_PUBLIC_API_URL}/admin/newsletter`,
          { email: email }
        );
        return response;
      } catch (error) {
        if (error.response && error.response.status === 400) {
          return error.response;
        }
        throw error;
      }
    },
    onSuccess: (res) => {
      if (
        res.status === 200 ||
        (res.status === 400 &&
          res.data.message === "You already subscribed to our newsletter")
      ) {
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
      setEmail("");
    },
    onError: (error) => {
      if (error.response) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Network error. Please try again.");
      }
    },
  });
  const handleSubmit = () => {
    if (email) {
      mutation.mutate(email);
    } else if (!isToastActive) {
      setIsToastActive(true);
      toast.warn("Please enter a valid email address.", {
        onClose: () => setIsToastActive(false),
      });
    }
  };

  const socialLinks = [
    {
      icon: "/linkedin.svg",
      href: "https://www.linkedin.com/company/main10-in",
      label: "LinkedIn",
    },
    {
      icon: "/instagram.svg",
      href: "https://www.instagram.com/main10.in",
      label: "Instagram",
    },
    {
      icon: "/facebook.svg",
      href: "https://www.facebook.com/main10.in",
      label: "Facebook",
    },
  ];

  // Create an array of links with their corresponding actions
  const learnMoreLinks = [
    { label: "Insights", href: "#insights" },
    { label: "FAQs", href: "#faqs" },
    {
      label: "Get a callback",
      action: () => openForm(),
      isButton: true,
    },
  ];

  return (
    <footer className="bg-primary w-full pt-16 md:pt-20 mt-16 md:mt-20 relative max-w-[1920px] mx-auto">
      {/* Decorative Background Elements */}
      <div className="absolute inset-0 bg-gradient-to-b from-primary/50 to-primary pointer-events-none" />
      <div className="absolute top-0 right-0 w-1/2 h-1/2 bg-primary200/10 blur-[100px] rounded-full" />

      <div className="relative z-10 mx-auto px-5 sm:px-20 ">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-12 gap-8 lg:gap-12">
          {/* Brand Section */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="col-span-1 sm:col-span-2 lg:col-span-4"
          >
            <div className="space-y-6">
              <Link href="/" className="inline-block">
                <Image
                  src="/main10_green.png" // Make sure to have a white version of your logo
                  width={120}
                  height={32}
                  alt="main10"
                  className="h-8 w-auto"
                />
              </Link>
              <p className="text-secondary/80 leading-relaxed">
                main10 is a property maintenance solution provider for property
                owners. Our skilled executives resolve complaints within 2
                hours, ensuring tenant satisfaction and delivering peace of mind
                for property owners.
              </p>

              {/* Social Media Links */}
              <div className="space-y-4">
                <h3 className="text-sm font-semibold tracking-wider text-secondary uppercase">
                  Connect With Us
                </h3>
                <div className="flex items-center gap-4">
                  {socialLinks.map((social) => (
                    <motion.a
                      key={social.label}
                      href={social.href}
                      target="_blank"
                      rel="noopener noreferrer"
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.95 }}
                      className="bg-primary200/10 p-2.5 rounded-full 
                        hover:bg-primary200/20 transition-colors duration-200"
                    >
                      <Image
                        src={social.icon}
                        width={20}
                        height={20}
                        alt={social.label}
                        className="w-5 h-5"
                      />
                    </motion.a>
                  ))}
                </div>
              </div>
            </div>
          </motion.div>

          {/* Learn More Section */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.1 }}
            className="col-span-1 sm:col-span-1 lg:col-span-2"
          >
            <h3 className="text-secondary font-semibold mb-6">Learn More</h3>
            <nav className="space-y-4">
              {learnMoreLinks.map((item) => (
                <motion.div
                  key={item.label}
                  whileHover={{ x: 5 }}
                  className="block"
                >
                  {item.isButton ? (
                    <button
                      onClick={item.action}
                      className="text-secondary/70 hover:text-secondary 
                        transition-colors duration-200 text-left"
                    >
                      {item.label}
                    </button>
                  ) : (
                    <Link
                      href={item.href}
                      className="text-secondary/70 hover:text-secondary 
                        transition-colors duration-200"
                    >
                      {item.label}
                    </Link>
                  )}
                </motion.div>
              ))}
            </nav>
          </motion.div>

          {/* Help Section */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            className="col-span-1 sm:col-span-1 lg:col-span-2"
          >
            <h3 className="text-secondary font-semibold mb-6">Help</h3>
            <nav className="space-y-4">
              <motion.button
                whileHover={{ x: 5 }}
                onClick={() => openForm()}
                className="text-secondary/70 hover:text-secondary 
                  transition-colors duration-200"
              >
                Contact Us
              </motion.button>
              {["Terms & Conditions", "Privacy Policy"].map((item) => (
                <motion.div key={item} whileHover={{ x: 5 }} className="block">
                  <Link
                    href={`${item === "Terms & Conditions" ? "/terms-and-conditions" : "/privacy-policy"}`}
                    className="text-secondary/70 hover:text-secondary 
                      transition-colors duration-200"
                  >
                    {item}
                  </Link>
                </motion.div>
              ))}
            </nav>
          </motion.div>

          {/* Newsletter Section */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.3 }}
            className="col-span-1 sm:col-span-2 lg:col-span-4"
          >
            <h3 className="text-secondary font-semibold mb-6">Newsletter</h3>
            <div className="space-y-4">
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email address"
                className="w-full px-4 py-3 rounded-lg bg-white/10 text-secondary 
                  placeholder:text-secondary/50 border border-secondary/10
                  focus:outline-none focus:ring-2 focus:ring-primary200
                  transition-all duration-200"
              />
              <motion.button
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                onClick={handleSubmit}
                className="w-full bg-primary200 text-primary py-3 rounded-lg
                  font-medium hover:bg-primary200/90 transition-colors
                  focus:outline-none focus:ring-2 focus:ring-primary200
                  disabled:opacity-70"
                disabled={!email}
              >
                Subscribe Now
              </motion.button>
            </div>
          </motion.div>
        </div>
      </div>
      <div className="relative mt-16 pt-4 border-t border-secondary/10">
        <div className="text-center pb-4">
          <p className="text-xs text-secondary/50">
            {new Date().getFullYear()} main10. All rights reserved.
          </p>
        </div>
      </div>

      {/* Enquiry Form Modal */}
    </footer>
  );
}
