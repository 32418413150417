"use client";

import { useRef, useEffect, useState } from "react";
import Image from "next/image";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import Link from "next/link";

const fetchBlogs = async () => {
  const response = await axios.get(
    `${process.env.NEXT_PUBLIC_API_URL}/admin/blog?limit=10&page=1`
  );
  return response.data.result.docs;
};

export default function Blogs() {
  const scrollRef = useRef(null);
  const [scrollOffset, setScrollOffset] = useState(380);
  useEffect(() => {
    console.log("width" + window.innerWidth);
    const handleResize = () => {
      // Adjust scroll offset to fit one card on screen for small screens
      const calculatedOffset =
        window.innerWidth < 768 ? window.innerWidth : 370;
      if (window.innerWidth < 768) {
        setScrollOffset(calculatedOffset - 80);
      } else {
        setScrollOffset(395);
      }
      console.log(calculatedOffset);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const {
    isLoading,
    error,
    data: blogs,
  } = useQuery({
    queryKey: ["Blogs"],
    queryFn: fetchBlogs,
  });

  const scrollLeft = () => {
    scrollRef.current?.scrollBy({ left: -scrollOffset, behavior: "smooth" });
  };

  const scrollRight = () => {
    scrollRef.current?.scrollBy({ left: scrollOffset, behavior: "smooth" });
  };

  const skeletonLoader = (
    <div className="flex items-center gap-10 overflow-x-scroll no-scrollbar py-3">
      {[...Array(3)].map((_, index) => (
        <div
          key={index}
          className="flex flex-col w-full md:w-[350px] max-w-[350px] h-[380px] bg-[#004B20] rounded-lg animate-pulse"
        >
          <div className="w-[330px] md:w-[350px] h-[180px] bg-gray-300 rounded-lg"></div>
          <div className="flex flex-col px-6 py-6 space-y-3">
            <div className="h-4 bg-gray-300 rounded w-full"></div>
            <div className="h-4 bg-gray-300 rounded w-4/5"></div>
            <div className="h-4 bg-gray-300 rounded w-3/5"></div>
          </div>
        </div>
      ))}
    </div>
  );

  const itemVariants = {
    hidden: {
      opacity: 0,
      y: 20,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: "easeOut",
      },
    },
  };

  const generateBlogListSchema = (blogs) => {
    if (!blogs) return null;

    const itemList = {
      "@context": "https://schema.org",
      "@type": "ItemList",
      itemListElement: blogs.map((blog, index) => ({
        "@type": "ListItem",
        position: index + 1,
        item: {
          "@type": "BlogPosting",
          headline: blog.title,
          description: blog.description.replace(/<[^>]*>/g, "").slice(0, 200),
          image: blog.thumbImage.location,
          datePublished: blog.createdAt,
          dateModified: blog.updatedAt,
          author: {
            "@type": "Person",
            name: blog.author || "Main10",
          },
          url: `${process.env.NEXT_PUBLIC_SITE_URL}/Insights/${blog.slug}`,
        },
      })),
    };

    const script = document.createElement("script");
    script.type = "application/ld+json";
    script.text = JSON.stringify(itemList);
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  };

  useEffect(() => {
    generateBlogListSchema(blogs);
  }, [blogs]);

  return (
    <div id="insights" className="section-gap">
      {/* Background Decoration */}
      <div
        className="absolute top-0 right-0 w-64 h-64 bg-primary200 rounded-full 
          blur-3xl opacity-30 -z-1 transform translate-x-1/2 -translate-y-1/2"
      />
      <div
        className="absolute bottom-0 left-0 w-64 h-64 bg-primary200 rounded-full 
          blur-3xl opacity-30 -z-1 transform -translate-x-1/2 translate-y-1/2"
      />

      {/* Header Section */}
      <div className="flex flex-col items-center mb-10 md:mb-16">
        <h2
          className="text-3xl md:text-4xl lg:text-5xl font-bold text-primary 
            tracking-tight text-center relative"
        >
          Explore our latest blogs
          <div
            className="absolute -bottom-4 left-1/2 transform -translate-x-1/2 
              w-20 h-1 bg-white rounded-full"
          />
        </h2>
      </div>

      {/* Navigation Controls */}
      <div className="flex justify-end gap-3 mb-8">
        <button
          aria-label="Scroll blogs left"
          title="Scroll left"
          type="button"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={scrollLeft}
          className="bg-primary w-12 h-12 rounded-full flex items-center justify-center 
              cursor-pointer shadow-lg hover:shadow-xl transition-all duration-300"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="none"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            viewBox="0 0 24 24"
            aria-hidden="true"
            role="presentation"
          >
            <polyline points="15 18 9 12 15 6"></polyline>
          </svg>
        </button>
        <button
          aria-label="Scroll blogs right"
          title="Scroll right"
          type="button"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={scrollRight}
          className="bg-primary w-12 h-12 rounded-full flex items-center justify-center 
              cursor-pointer shadow-lg hover:shadow-xl transition-all duration-300"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="none"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            viewBox="0 0 24 24"
            aria-hidden="true"
            role="presentation"
          >
            <polyline points="9 18 15 12 9 6"></polyline>
          </svg>
        </button>
      </div>

      {/* Blogs Grid with Animation */}
      <div
        ref={scrollRef}
        className="grid grid-flow-col gap-6 overflow-x-auto no-scrollbar w-full 
            scroll-smooth pb-4"
        style={{ gridAutoColumns: "clamp(280px, 100%, 350px)" }}
      >
        {isLoading ? (
          <div variants={itemVariants}>{skeletonLoader}</div>
        ) : error ? (
          <div variants={itemVariants} className="text-red-500">
            Failed to load blogs: {error.message}
          </div>
        ) : (
          blogs?.map((blog) => (
            <div key={blog._id} whileHover={{ y: -5 }}>
              <Link href={`/Insights/${blog.slug}`}>
                <div
                  className="bg-white rounded-2xl shadow-lg overflow-hidden
                      hover:shadow-xl transition-all duration-300"
                >
                  <div className="relative h-[200px] overflow-hidden">
                    <Image
                      src={blog.thumbImage.location}
                      width={350}
                      height={200}
                      alt={blog.title.replace(/\s+/g, "_")}
                      className="w-full h-full object-cover transform transition-transform 
                          duration-300 hover:scale-110"
                      loading="lazy"
                    />
                  </div>
                  <div
                    className="p-6 bg-white"
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    transition={{ delay: 0.2 }}
                  >
                    <h3
                      className="text-xl font-semibold text-primary mb-2 
                        line-clamp-2 hover:text-primary-600 transition-colors"
                    >
                      {blog.title}
                    </h3>
                    <div className="text-gray-600 text-sm line-clamp-3">
                      <div
                        dangerouslySetInnerHTML={{
                          __html:
                            blog.description.slice(0, 200) || "Read more...",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          ))
        )}
      </div>
    </div>
  );
}
