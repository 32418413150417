"use client";

import { BANNER_ONE } from "../../constants/data";
import Image from "next/image";
import { useForm } from "../../context/FormContext";

export default function Banner(props) {
  const { openForm } = useForm();

  return (
    <>
      <div className="section-gap !bg-primary600 mb-[90px]">
        <div className="flex items-center justify-between">
          <div className="flex flex-col items-start gap-3 md:gap-[18px]">
            <h2 className="font20_30 font-semibold text-primary">
              {props.heading}
            </h2>
            <p className="text-sm text-primary">{props.description}</p>
          </div>
          <div className="hidden md:block">
            {props.button ? (
              <>
                <button
                  onClick={() => {
                    openForm();
                  }}
                  className="bg-primary rounded-lg w-[191px] h-[52px] text-primary600 font-medium text-base"
                >
                  {BANNER_ONE.buttonText}
                </button>
              </>
            ) : (
              <div className="flex flex-col md:flex-col lg:flex-row items-center gap-8">
                <a
                  href={process.env.NEXT_GOOGLE_PLAY_STORE_LINK}
                  target="_blank"
                  rel="noreferrer"
                  className="relative group"
                >
                  <Image
                    src={"/banner-app-store.svg"}
                    width={152}
                    height={50}
                    alt="play"
                  />
                </a>
                <div className="relative group">
                  <Image
                    src={"/banner-play-store.svg"}
                    width={152}
                    height={50}
                    alt="app"
                    className="opacity-50"
                  />
                  <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-lg">
                    <span className="text-white font-medium">Coming Soon</span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="block md:hidden mt-4">
          {props.button ? (
            <>
              <button
                onClick={() => {
                  openForm();
                }}
                className="bg-primary rounded-lg w-[164px] md:w-[191px] h-[44px] md:h-[52px] text-primary600 font-medium text-sm md:text-base"
              >
                {BANNER_ONE.buttonText}
              </button>
            </>
          ) : (
            <div className="flex items-center gap-4 md:gap-8">
              <a
                href={process.env.NEXT_GOOGLE_PLAY_STORE_LINK}
                target="_blank"
                rel="noreferrer"
                className="relative group"
              >
                <Image
                  src={"/banner-app-store.svg"}
                  width={152}
                  height={50}
                  alt="play"
                  className="w-[132px] md:w-[152px] h-[44px] md:h-[50px]"
                />
              </a>
              <div className="relative group">
                <Image
                  src={"/banner-play-store.svg"}
                  width={152}
                  height={50}
                  alt="app"
                  className="w-[132px] md:w-[152px] h-[44px] md:h-[50px] opacity-50"
                />
                <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-lg">
                  <span className="text-white font-medium">Coming Soon</span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
